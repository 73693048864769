<template>


<div class="home">
    <div class="row">
      <div class="col-md-12" style="margin-top:70px !important; height:70px !important; padding:30px 0 0px 10px; position:fixed; z-index: 1; background:#fff; border-bottom: solid 1px #ccc; padding-left:40px">
        <span style="color:#777; font-size:18px;padding:5px;">Settings</span>/<span style="padding:5px; color:#555">CAPS Accounts</span>
      </div>
    </div>
  
    <div style="background: #f5f5f5; margin-top:150px; " class=" container-fluid row ">
        <v-row style="margin:0 !important; padding:0 !important">
    <v-col cols="12" style="">
        <div style="margin:auto; padding:20px; margin-top:100px" class="col-md-5" v-if="show">
                <v-card elevation="3" height="300">
                    <v-card-title class="text-h7" style="color:#666">
                        Find CAPS Account
                    </v-card-title>
                    <v-divider style="margin-top:0px"></v-divider>

                    <v-col cols="9" style="margin:auto;padding-top:45px" pa-4>
                        <v-text-field outlined label="Email Address" v-model="email" dense></v-text-field>
                        <v-btn color="primary" @click="validateEmail" elevation='2' class="col-md-12" :disabled="email==''">Find Account</v-btn>
                    </v-col>
                </v-card>
            </div>

           <div  v-else style="margin-top:50px">
            <btn @click="back" class="mb-4">
                          Back
            </btn>
        
      <v-card min-height="400">
        
        <v-card-title class="text-h7" style="color:#666">
            CAPS account information
        </v-card-title>
        <v-divider style="margin-top:0px; margin-bottom: 50px;"></v-divider>

             <div class="row">
                  <!-- <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Corporis, ut! Tenetur reprehenderit minima,
                       placeat adipisci expedita sint.
                  Fugiat voluptas possimus adipisci quisquam eius eaque, rerum nobis architecto facilis vero dolor.</p> -->
                  <div class="col-md-1">
                    </div>
                    <div class="col-md-2">
                    </div>
                  <div class="col-md-4">
                      <div class="flex">
                          <v-text-field disabled class="" :value="name" outlined dense label="Name"></v-text-field>
                      </div>
                      <div class="flex">
                      <v-text-field disabled :value="surname" outlined dense label="Surname"></v-text-field>
                      </div>
                      <div class="flex">
                      <v-text-field disabled :value="email" outlined dense label="Email"></v-text-field>
                      </div>
                      <div class="flex">
                      <v-text-field disabled :value="phone" outlined dense label="Phone"></v-text-field>
                      </div>
                      
                      
                  </div>
                  <div class="col-md-1">
                </div>
                  <div class="col-md-3">
                   <div>
                    Account Operations
                   </div>
                   <v-divider></v-divider>
                      <v-btn @click="updatePassword">
                          Change Password
                      </v-btn>
    </div>
              </div>
           
          </v-card>
        </div>
          </v-col>
          </v-row>
          </div>
          </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Swal from 'sweetalert2';
  export default {
      data() {
          return {
              show: true,
              password: '',
              email: '',
              phone: '',
              name: '',
              surname: ''
          }
      },
      methods: {
          async validateEmail() {
              if (!this.email) return Swal.fire({
                  icon: 'info',
                  title: 'Please enter email'
              })
              try {
                var url = this.$appUrl+'getCapsAccount';
                  const res = await axios.post(url, {
                      email: this.email
                  });
                  if (res.data.code == 's200') {
                      const data = res.data.message;
                      this.email = data.email;
                      this.phone = data.phone;
                      this.surname = data.surname;
                      this.name = data.name;
                      this.show = false;
                  }else{
                      Swal.fire({icon: 'error', title: 'Oops..', text: res.data.message})
                  }
              } catch (error) {
                  console.log(error);
              }
          },
          back(){
              this.show = true;
              this.email = '';
              this.phone = '';
              this.surname = '';
              this.name = '';
          },
          async updatePassword(){
              try {
                var url = this.$appUrl+'resetCapsPass'
              const res = await axios.post(url, {
                      email: this.email,
                  });
  
                  if(res.data.code == 's200'){
                      Swal.fire({icon: 'success', title: 'Successful', text: 'Password updated successfully'})
                      this.show = true;
                      this.email = '';
                     
  
                  }else{
                      Swal.fire({icon: 'error', title: 'Oops..', text: res.data.message})
                  }
              } catch (error) {
                  console.log(error);
              }
          }
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .main-wrapper {
      .header {
          padding: 1rem;
          font-weight: bold;
          background: #D6ECF5;
      }
  }
  
  .wrapper {
      min-height: 600px;
      margin-bottom: 10px;
      display: flex;
      padding: 2rem;
      align-items: center;
      justify-content: center;
  
  }
  
  .confirm-wrapper{
      max-width:500px;
  }
  
  .text-field {
      min-width: 300px
  }
  
  .flex{
      display: flex;
      align-items: flex-end;
  }
  .btn {
      width: 200px !important;
      display: inline-block;
      // padding: 1.5rem !important;
      text-transform: capitalize;
      height: 45px !important;
      background: #21618C !important;
      color: #fff;
  
      &:hover {
          color: #fff;
      }
  }
  
  .password-wrapper{
     width: 800px;
     margin: 0 auto;
     text-align: left;
  }
  </style>
  